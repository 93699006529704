// 
// user-variables.scss
// Use this to override Bootstrap and Createx variables
//

// Example of a variable override to change Createx background color
// Remove the "//" to comment it in and see it in action!
//$body-bg: #12263F;


// Brand colors
$primary:               #755965 !default;

// Gradient
$gradient-to-color:     #93808d !default;
